import { extendTheme, type ThemeConfig } from '@chakra-ui/react'

const themeConfig: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false
}

export const theme = extendTheme({
  config: themeConfig,
  colors: {
    inedita: {
      orange: {
        '50': '#fff9f2',
        '100': '#fff2e6',
        '200': '#ffdfbf',
        '300': '#ffcb99',
        '400': '#ffa54d',
        '500': '#ff7e00',
        '600': '#e67100',
        '700': '#bf5f00',
        '800': '#994c00',
        '900': '#7d3e00'
      },
      blue: {
        '50': '#f5f5f8',
        '100': '#eaecf1',
        '200': '#cbcfdb',
        '300': '#abb2c6',
        '400': '#6c799b',
        '500': '#2D3F70',
        '600': '#293965',
        '700': '#222f54',
        '800': '#1b2643',
        '900': '#161f37'
      }
    }
  },
  fonts: {},
  styles: {
    body: {
      background: ''
    }
  },
  breakpoints: {
    max: '1480px'
  }
})
