import 'react-toastify/dist/ReactToastify.css'
import { Box, ChakraProvider, Flex, Text } from '@chakra-ui/react'
import { AppProps } from 'next/app'
import Image from 'next/image'
import { ToastContainer } from 'react-toastify'

import background from 'assets/background.png'
import logo from 'assets/logo.png'

import { theme } from 'styles/theme'

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  return (
    <ChakraProvider theme={theme}>
      <Flex
        minH="100vh"
        h="100%"
        flex="1"
        justifyContent="center"
        alignItems="center"
        bg={`url(${background.src})`}
        bgRepeat="no-repeat"
        bgSize="cover"
        bgPos="center center"
        py="6"
      >
        <Flex
          bg="blackAlpha.900"
          py="6"
          borderRadius={['none', 'none', 'md']}
          boxShadow="lg"
          maxW={['100%', '100%', 'min(90%, 40rem)']}
          flexDir="column"
        >
          <Flex
            mb="4"
            p="2"
            bg="inedita.orange.500"
            justifyContent="center"
            alignItems="center"
          >
            <Image src={logo} width="66" height="70" />
            <Box ml="4" color="inedita.blue.500">
              <Text fontSize="3xl" fontWeight="bold">
                Inédita Energia
              </Text>
            </Box>
          </Flex>
          <Box px="6">
            <Component {...pageProps} />
          </Box>
          <ToastContainer theme="dark" />
        </Flex>
      </Flex>
    </ChakraProvider>
  )
}

export default MyApp
